import React from 'react';

const decodeMessage = (message, count) => {
    let data = [];
    //console.log(message);
    //A message might arrive, it would look like <C #FFFFFF>Hello World, there is a <C #FF0000>fox<C #FFFFFF> waiting for you.\r\nDo you wish to greet him.
    let splitMessage = message.body.split(/[\r\n]+/).filter(text => (text.length > 0) && (text !== String.fromCharCode(0)));
    message.lineCount = splitMessage.length;
    //Break up the text on the <C ... > colour codes from the mud. Build an array of text fragments to be displayed.
    var lastCode = "";
    splitMessage.forEach((fragment) => {
        let line = [];
        let colourCodedMessages = fragment.split(/(?=<C)/);
        colourCodedMessages.forEach(ctext => {
            let subCodes = ctext.split(/(?<=>)/);
            //console.log('fragment Text', fragment);
            let item = { colour: lastCode, text: "" };
            subCodes.forEach(code => {
                //console.log('fragment section', code);
                if (code.match(/^<C.*>$/)) {
                    item.colour = code;
                    lastCode = code;
                } else
                    item.text += code;

            });
            line.push(item);
        });
        
        data.push(line);
    });

    //From the list of text items, take each fragment and create the markup to be shown to the user so it's not all recreated 
    // when re-rendering as new lines come through from the mud
    
    let data2 = [];
    data.forEach((line, lineIndex) => {
        line.forEach((fragment, fragmentIndex) => {                        
            data2.push(displayFragment(fragment,count,lineIndex, fragmentIndex));
        });
        data2.push(<br key={count+"."+lineIndex}/>);     
    });

    return data2;
}

const getFragmentStyle = (colour) => {
    let styleRules = {  };
    if (colour.length === 0) {
        //do nothing!
    } else if (colour.length === 11) {
        styleRules.color = colour.substring(3, 10);
    } else if (colour.length === 16) {
        //<C BACK #000099>
        //console.log('Unhandled Background', colour.substring(7, 15))
        styleRules.color = 'white';
        styleRules.backgroundColor = colour.substring(7, 15);
    } else if (colour.length === 19) {
        //<C #000099 #000099>
        //console.log('Unhandled Background', colour.substring(11, 18))
        styleRules.color = colour.substring(3, 10);
        styleRules.backgroundColor = colour.substring(11, 18);
    } else {
        //<C BACK #000099>
        console.log('Unhandled Code ('+colour.length+')', colour)
    }
    return styleRules;
}

const displayFragment = (fragment, messageIndex, lineIndex, fragmentIndex) => {
    const styleRules = getFragmentStyle(fragment.colour);
    return (
        <span
            key={messageIndex + "."+ lineIndex + "." + fragmentIndex} style={styleRules}
            className="mudoutput">
            {fragment.text}
        </span>
    );
}

export {decodeMessage};